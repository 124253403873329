import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router';
import vueCookie from 'vue-cookie';
import vars from './config/const.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    loggingIn: false,
    loginError: null
  },
  mutations: {
    loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.accessToken = null;
    }
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit('loginStart');
      // axios.post('api/login.php', {
      axios.post(vars.SERVER_ADDRESS + '/users/validate',{
        username: loginData.username,
        password: loginData.password
      },)
        .then(response => {
          if(response.data.result) {
            vueCookie.set('accessToken', response.data.result);
            commit('loginStop', null);
            commit('updateAccessToken', response.data.result);

            if (loginData.rememberMeChecked) {
              vueCookie.set('username', loginData.username, 60);
              vueCookie.set('password', loginData.password, 30);
            } else {
              vueCookie.delete('username');
              vueCookie.delete('password');
            }
            router.push('/home');
          }else{
            if(response.data.error != null) commit('loginStop', response.data.error);
            else commit('loginStop', "Undefined Error");
            commit('updateAccessToken', null);
          }
        })
        .catch(error => {
          commit('loginStop', error.response.data.error);
          commit('updateAccessToken', null);
        })
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', vueCookie.get('accessToken'));
    },
    logout({ commit }) {
      vueCookie.delete('accessToken');
      commit('logout');
      router.push('/login');
    }
  }
})
