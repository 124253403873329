module.exports = {
  WEBSITE_ADDRESS: "https://ima.kaiser.fyi/",
  SERVER_ADDRESS: "https://ima.api.kaiser.fyi",
  // SERVER_ADDRESS: 'http://127.0.0.1:3000',
  // SERVER_ADDRESS: 'http://167.172.173.57:3000',
  FRONTEND_LOCATION: "/var/www/ima/public_html/",
  FILES_LOCATION: "https://files.kaiser.fyi/ima/",
  LOGGER: "https://log.kaiser.fyi",


  LOGGER_SALT: "54686973206973206e6f7420612073617665206b6579"
}
