<template>
  <div class="login">
      <md-card class="contentCard">
      <div class="md-title">IMA CMS - Login</div>
        <div class="md-subhead">If any problems with the login occur, click <a href="mailto:Ruben.Kaiser@t-online.de"> help</a>.</div>

        <form @submit.prevent="loginSubmit">
          <md-field>
            <label>Login Name</label>
            <md-input v-model="username" name="username" md-clearable ></md-input>
          </md-field>
          <md-field>
            <label>Password</label>
            <md-input v-model="password" name="password" type="password" md-clearable></md-input>
          </md-field>
          <div v-if="loggingIn"><md-progress-bar md-mode="query"></md-progress-bar></div>


          <md-checkbox type="checkbox" id="rememberMeBox" v-model="rememberMeChecked"></md-checkbox>
          <label for="rememberMeBox" v-if="rememberMeChecked">Remember me!</label>
          <label for="rememberMeBox" v-else>Don't remember me.</label>

          <br />
          <md-button class="md-raised md-primary loginButton" type="submit">Login</md-button>
          <p v-if="loginError">{{ loginError }}</p>
        </form>
      </md-card>
  </div>
</template>
<script>
    import vueCookie from 'vue-cookie';
    import { mapState, mapActions } from 'vuex';

    export default {
        name: "Login",
        beforeMount(){
          this.checkCookie();
        },
        data () {
            return {
                rememberMeChecked: true,
                username: "",
                password: ""
            }
        },
        computed: {
            ...mapState([
                'loggingIn',
                'loginError',
                'loginSuccessful'
            ])
        },
        methods:{
            ...mapActions([
                'doLogin'
            ]),
            loginSubmit() {
                this.doLogin({
                    username: this.username,
                    password: this.password,
                    rememberMeChecked: this.rememberMeChecked
                });
            },
            checkCookie: function () {
                if(vueCookie.get("username") != null && vueCookie.get("password") != null){
                    this.username = vueCookie.get("username");
                    this.password = vueCookie.get("password");
                }
            }
        },
        filters: {

        }
    }
</script>
<style scoped>
  .login{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .contentCard{
    padding: 2em;
    width: 50em;
  }
  .loginButton{
    margin-left: 0;
  }
  md-progress-bar{
    margin: 10px;
  }
</style>
