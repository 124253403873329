import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import store from './store.js';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('./views/Logout.vue')
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('./views/Home.vue')
    },


    {
      path: '/landing',
      name: 'Landing Page',
      component: () => import('./views/Landing.vue')
    },
    {
      path: '/course',
      name: 'Course',
      component: () => import('./views/Course.vue')
    },
    {
      path: '/faculty',
      name: 'Faculty',
      component: () => import('./views/Faculty.vue')
    },
    {
      path: '/media',
      name: 'media',
      component: () => import('./views/Media.vue')
    },
    {
      path: '/files',
      name: 'files',
      component: () => import('./views/Files.vue')
    },
    {
      path: '/network',
      name: 'network',
      component: () => import('./views/Network.vue')
    },
    {
      path: '/apply',
      name: 'apply',
      component: () => import('./views/Apply.vue')
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: () => import('./views/Imprint.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/Privacy.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue')
    },
    {
      path: '/masterclass',
      name: 'masterclass',
      component: () => import('./views/Masterclass.vue')
    },
    {
      path: '*',
      redirect: '/login'
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch('fetchAccessToken');
  if (to.fullPath !== '/login') {
    if (store.state.accessToken == null) {
      router.push("/login");
    }
  }
  next();
});

export default router;
